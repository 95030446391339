<bb-header #header></bb-header>

<ui-bb-loading-page *ngIf="loadingVenue$ | async" />

<ng-container *ngIf="venueDetail$ | async as venueDetail">
	<ui-bb-swiper [urls]="venueDetail.bannersURLoriginal"></ui-bb-swiper>
	<!--<div class="page px-16" style="margin-bottom: 16px">
		<ui-bb-alert-box title="Check-in indisponível" *ngIf="venueDetail.checkin.status === 'disabled'">
			Esse restaurante não aceita check-in. Reserve uma data e aproveite.
		</ui-bb-alert-box>
	</div>-->
	<ng-template #buttons>
		<ui-bb-button
			type="secondary"
			style="width: 160px; font-size: 12px"
			(clickButton)="onReservationClick(venueDetail)"
			[styleDisabled]="!venueDetail.reservation?.enabled"
		>
			RESERVAR UMA MESA
		</ui-bb-button>
		<ui-bb-button
			type="primary"
			style="width: 160px; font-size: 12px"
			(clickButton)="onCheckinClick(venueDetail)"
			[styleDisabled]="!venueDetail.checkin.available || !venueDetail.checkin.available"
		>
			CHECK-IN
		</ui-bb-button>
	</ng-template>
	<ng-template #modalLogin>
		<div style="display: flex; flex-direction: column; height: 100%">
			<article style="flex-grow: 1">
				<p [style]="isMobile ? 'line-height: 20px;margin-top:24px;' : '' ">
					Para reservar uma mesa ou fazer o check-in no restaurante, será necessário confirmar seu
					login pela Livelo.
				</p>
				<p style="margin-top: 16px">
					Não se preocupe, você voltará para a página do restaurante logo depois disso.
				</p>
			</article>
			<div style="height: 24px"></div>
			<ui-bb-button
				type="secondary"
				style="width: 100%; font-size: 12px; padding: 12px"
				(clickButton)="openLoginLivelo()"
				footer
			>
				CONTINUAR
			</ui-bb-button>
		</div>
	</ng-template>

	<ng-template #checkinUnavailable>
		<article class="checkin-unavailable">
			<span *ngIf="venueDetail.checkin.status === 'disabled'">
				Esse restaurante não aceita Check-in.<br />
				Reserve uma mesa e aproveite!
			</span>
			<ng-container *ngIf="venueDetail.checkin.status === 'unavailable'">
				<ui-bb-box-message template="checkinUnavailable" />
				<p style="margin-top: 41px">Horários disponíveis do check-in</p>
				<p>{{venueDetail.checkin.time?.startTime}} às {{venueDetail.checkin.time?.endTime}}</p>

				<div style="margin: 24px 0"></div>
				<ui-bb-button
					type="secondary"
					style="width: 160px; font-size: 12px"
					(clickButton)="onReservationClick(venueDetail)"
					[styleDisabled]="!venueDetail.reservation?.enabled"
				>
					RESERVAR UMA MESA
				</ui-bb-button>
			</ng-container>
		</article>
	</ng-template>
	<ng-template #reservationUnavailable>
		<p class="reservationUnavailableTxt">Este restaurante ainda não aceita reservas</p>
	</ng-template>

	<ng-template #descriptionLong>
		<p class="txtModal">{{ venueDetail.descriptionLong }}</p>
		<div style="height: 48px"></div>
	</ng-template>

	<ng-template #hours>
		<div [style]="isMobile ? 'margin-top: 19px' : ''">
			<div class="hours">
				<div
					class="hour txtInfo txtInfo--small"
					*ngFor="let operationHour of venueDetail.operationHours.value"
				>
					<p class="hour__day">{{ operationHour.day }}</p>
					<p>{{ operationHour.times }}</p>
				</div>
				<div>
					Horário de funcionamento do Check-In: <br />
					<span *ngIf="venueDetail.checkin.enabled">
						{{ venueDetail.checkin.time?.startTime }} às {{ venueDetail.checkin.time?.endTime }}
					</span>
					<span *ngIf="!venueDetail.checkin.enabled">
						Este restaurante não aceita check-in. Faça uma reserva e aproveite!
					</span>
				</div>
			</div>
		</div>
	</ng-template>

	<div class="page-venue-details">
		<ng-container *ngIf="!isMobile">
			<div class="info-desktop">
				<div class="info-desktop__left">
					<h1 class="bbTitle">{{ venueDetail.name }}</h1>
					<p class="txtInfo">{{ venueDetail.address.value }}</p>
					<p class="txtPhone">{{ venueDetail.phone[0].value }}</p>

					<div class="tags">
						<ui-bb-tag [optionalBorder]="false" *ngFor="let cuisine of venueDetail.cuisines"
							>{{ cuisine }}</ui-bb-tag
						>
					</div>
					<div class="boxBtn">
						<ui-bb-button
							type="tertiary"
							style="width: 162px"
							(clickButton)="modalCenterAbout.openModal()"
						>
							<p class="txtBtn">Sobre o restaurante</p>
						</ui-bb-button>
						<a class="menu" [href]="venueDetail.liveMenuURL" target="_blank">
							<ui-bb-button type="tertiary" style="width: 114px">
								<p class="txtBtn">Ver cardápio</p>
							</ui-bb-button>
						</a>
						<ui-bb-button
							type="tertiary"
							style="width: 114px"
							(clickButton)="modalCenterHours.openModal()"
						>
							<p class="txtBtn">Ver Horários</p>
						</ui-bb-button>
					</div>

					<ui-common-divisor top="32px" bottom="32px" />

					<ui-bb-benefits [benefits]="venueDetail.benefits" [venueId]="venueDetail._id" />

					<div style="display: flex; gap: 12px; margin-top: 32px">
						<ng-container *ngTemplateOutlet="buttons"></ng-container>
					</div>
				</div>
				<a [href]="venueDetail.mapsLink" target="_blank" style="margin-left: auto">
					<img class="info__map" [src]="venueDetail.mapsDesk" />
				</a>
			</div>
			<ui-bb-modal-center #modalCheckin (clickOutside)="closeModalCheckin()">
				<feature-bb-checkin
					*ngIf="openCheckin"
					(goOrders)="goOrderHistory()"
					(registerCard)="openRegisterCard()"
				/>
			</ui-bb-modal-center>

			<ui-bb-modal-center #modalReserva (clickOutside)="closeModalReserva()">
				<div style="height: min(70vh, 750px); position: relative">
					<feature-bb-reservation *ngIf="openReservation" />
				</div>
			</ui-bb-modal-center>

			<ui-bb-modal-center
				title="Check-in Não realizado"
				#modalCheckinFailure
				(clickOutside)="modalCheckinFailure.closeModal()"
				><div style="padding: 16px">
					<ng-container *ngIf="errorConfirm | async as errorConfirm">
						@if(errorConfirm && errorConfirm.includes('Não é permitido')) { {{errorConfirm}} }
						@else {
						<p>Não conseguimos realizar seu check-in para o dia/horário que você ceseja.</p>
						}
					</ng-container>
				</div>
			</ui-bb-modal-center>
			<ui-bb-modal-center
				title="Check-in indisponível"
				#modalCenterCheckinUnavailable
				(clickOutside)="modalCenterCheckinUnavailable.closeModal()"
			>
				<div style="padding-inline: 12px">
					<ng-container *ngTemplateOutlet="checkinUnavailable"></ng-container>
				</div>
			</ui-bb-modal-center>

			<ui-bb-modal-center
				title="Reserva indisponível"
				#modalCenterReservationUnavailable
				(clickOutside)="modalCenterReservationUnavailable.closeModal()"
			>
				<div style="padding-inline: 16px">
					<ng-container *ngTemplateOutlet="reservationUnavailable"></ng-container>
				</div>
			</ui-bb-modal-center>

			<ui-bb-modal-center
				title="Reserva não disponível"
				#modalCenterAvailabilitiesFailure
				(clickOutside)="modalCenterAvailabilitiesFailure.closeModal()"
			>
				<div style="padding: 12px">
					<ng-container *ngIf="errorAvailability$ | async as error"> {{error}} </ng-container>
					<div style="height: 12px"></div>
					<ui-bb-button
						type="secondary"
						style="width: 100%; padding: 0 16px"
						(clickButton)="modalCenterAvailabilitiesFailure.closeModal()"
						>FECHAR</ui-bb-button
					>
				</div>
			</ui-bb-modal-center>

			<ui-bb-modal-center
				title="Fazer login"
				style="color: var(--primitives-grey-500)"
				#modalBottomLogin
			>
				<div style="padding-inline: 12px">
					<ng-container *ngTemplateOutlet="modalLogin"></ng-container>
				</div>
			</ui-bb-modal-center>

			<ui-bb-modal-center
				#modalReservationFailure
				[close]="false"
				(clickOutside)="modalReservationFailure.closeModal()"
			>
				<div style="padding: 16px">
					<div style="display: flex; align-items: center">
						<ui-common-close-fill />
						<p class="title">Reserva não realizada</p>
					</div>
					<p class="content">
						<ng-container *ngIf="errorConfirmReservation$ | async as error">
							@if (error.includes('permitido fazer')) {
							<p>Não é possível realizar mais de uma reserva por dia no mesmo restaurante.</p>
							} @else {
							<p>Não conseguimos realizar sua reserva para o dia/horário que você deseja</p>
							}
						</ng-container>
					</p>
				</div>
				<ui-bb-footer position="static" footer>
					<ui-bb-button
						footer
						type="secondary"
						style="width: 100%; padding: 0 16px"
						(clickButton)="modalReservationFailure.closeModal()"
					>
						TENTAR NOVAMENTE
					</ui-bb-button>
				</ui-bb-footer>
			</ui-bb-modal-center>
			<ui-bb-modal-center
				title="Sobre o restaurante"
				overflow="auto"
				#modalCenterAbout
				(clickOutside)="modalCenterAbout.closeModal()"
			>
				<div style="padding-inline: 12px">
					<ng-container *ngTemplateOutlet="descriptionLong"></ng-container>
				</div>
			</ui-bb-modal-center>

			<ui-bb-modal-center
				title="Horário de funcionamento"
				overflow="auto"
				#modalCenterHours
				(clickOutside)="modalCenterHours.closeModal()"
			>
				<div style="padding-inline: 12px; padding-bottom: 24px">
					<ng-container *ngTemplateOutlet="hours"></ng-container>
				</div>
			</ui-bb-modal-center>
		</ng-container>

		<ng-container *ngIf="isMobile">
			<ui-bb-modal-bottom
				title="Check-in indisponível"
				[height]="venueDetail.checkin.status === 'disabled' ? '264px' : ''"
			>
				<ng-container *ngTemplateOutlet="checkinUnavailable"></ng-container>
			</ui-bb-modal-bottom>

			<ui-bb-modal-bottom
				title="Reserva indisponível"
				[height]="'204px'"
				#modalBottomReservationUnavailable
			>
				<ng-container *ngTemplateOutlet="reservationUnavailable"></ng-container>
			</ui-bb-modal-bottom>

			<ui-bb-modal-bottom
				title="Fazer login"
				#modalBottomLogin
				style="color: var(--primitives-grey-500)"
				height="400px"
			>
				<ng-container *ngTemplateOutlet="modalLogin"></ng-container>
			</ui-bb-modal-bottom>
			<ui-bb-modal-bottom
				#modalRegisterPhone
				title="Cadastro incompleto"
				style="color: var(--primitives-grey-500)"
				height="400px"
			>
				<p style="line-height: 20px; margin-top: 24px">
					Olá! Para reservar uma mesa ou fazer o check-in no restaurante, será necessário completar
					o seu registro.
				</p>
				<p style="margin-top: 16px">
					Não se preocupe, você voltará para a página do restaurante logo depois disso.
				</p>
				<ui-bb-footer position="static" footer style="padding: 0 16px">
					<ui-bb-button
						type="secondary"
						style="width: 100%; font-size: 12px"
						(clickButton)="openRegisterPhonePage()"
					>
						CONTINUAR
					</ui-bb-button>
				</ui-bb-footer>
			</ui-bb-modal-bottom>

			<ui-bb-modal-bottom title="Sobre o restaurante" overflow="auto" #modalBottomAbout>
				<ng-container *ngTemplateOutlet="descriptionLong"></ng-container>
			</ui-bb-modal-bottom>
			<ui-bb-modal-bottom title="Horário de funcionamento" overflow="auto" #modalBottomHours>
				<ng-container *ngTemplateOutlet="hours"></ng-container>
			</ui-bb-modal-bottom>
			<h1 class="bbTitle">{{ venueDetail.name }}</h1>
			<p class="txtInfo">{{ venueDetail.address.value }}</p>
			<p class="txtPhone">{{ venueDetail.phone[0].value }}</p>

			<div class="tags">
				<ui-bb-tag [optionalBorder]="false" *ngFor="let cuisine of venueDetail.cuisines"
					>{{ cuisine }}</ui-bb-tag
				>
			</div>

			<ui-bb-benefits [benefits]="venueDetail.benefits" [venueId]="venueDetail._id" />

			<div class="boxOption" style="margin-top: 5px" (click)="modalBottomHours.openModal()">
				<p>Ver horários</p>
				<i class="fa fa-chevron-up boxOption__icon"></i>
			</div>

			<div class="boxOption" style="margin-top: 5px" (click)="modalBottomAbout.openModal()">
				<p>Sobre o restaurante</p>
				<i class="fa fa-chevron-up boxOption__icon"></i>
			</div>

			<div class="boxBtn" *ngIf="isMobile">
				<a class="menu" [href]="venueDetail.liveMenuURL" target="_blank" style="width: 100%">
					<ui-bb-button style="width: 100%" type="tertiary"
						><p class="txtBtn">Ver cardápio</p></ui-bb-button
					></a
				>
				<a class="menu" style="width: 100%" [href]="venueDetail.mapsLink" target="_blank">
					<ui-bb-button style="width: 100%" type="tertiary"
						><p class="txtBtn">Ver Localização</p></ui-bb-button
					></a
				>
			</div>
		</ng-container>
	</div>
	<ng-container *ngIf="isMobile">
		<ui-bb-footer position="fixed">
			<ng-container *ngTemplateOutlet="buttons"></ng-container>
		</ui-bb-footer>
	</ng-container>
</ng-container>

<feature-bb-handle-login />
