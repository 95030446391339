<section class="accordion">
	<div
		*ngFor="let item of items; index as i"
		class="accordion__item"
		[class.disabled]="item.disabled"
		[class.active]="expanded.has(i)"
	>
		<ng-container
			[ngTemplateOutlet]="item?.customHeader?.templateRef || defaultHeader"
			[ngTemplateOutletContext]="{ $implicit: item, index: i, toggle: getToggleState(i) }"
		></ng-container>
		<div
			class="accordion__content"
			[class.expanded]="expanded.has(i)"
			[@contentExpansion]="expanded.has(i) ? 'expanded' : 'collapsed'"
			(click)="item.disabled ? {} : toggleState(i)"
		>
			<ng-container *ngTemplateOutlet="item.content.templateRef"></ng-container>
		</div>
		<ui-common-divisor *ngIf="divisor.show" [top]="divisor.top" [bottom]="divisor.bottom" />
	</div>
</section>

<ng-template #defaultHeader let-item let-index="index">
	<header class="accordion__header" (click)="item.disabled ? {} : toggleState(index)">
		<ng-container *ngTemplateOutlet="item?.customTitle?.templateRef || defaultTitle"></ng-container>
		<button class="accordion__toggle-btn" [disabled]="item.disabled">
			<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M1 13L7 7L0.999999 1"
					stroke="var(--icon-color-black)"
					stroke-opacity="0.7"
					stroke-width="1.25"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</button>
	</header>
	<ng-template #defaultTitle>
		<p class="accordion__title">{{ item?.title }}</p>
	</ng-template>
</ng-template>
