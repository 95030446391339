import { Component } from '@angular/core'

@Component({
	selector: 'ui-common-or-divisor',
	standalone: true,
	template: ` <article>
		<div class="orDivisor__wrapper">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="2"
				viewBox="0 0 100 2"
				fill="none"
				preserveAspectRatio="none"
			>
				<path d="M0 1H247" stroke="var(--content-divider, #EAEAEA)" />
			</svg>
			<span class="orDivisor__text">OU</span>
		</div>
	</article>`,
	styles: [
		`
			.orDivisor {
				&__wrapper {
					position: relative;
				}
				&__text {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					padding: 0px 4px;
					display: inline-block;
					background-color: var(--background-page, #fff,);
					color: var(--content-txt, #9f9f9f);
					font-size: 12px;
					letter-spacing: 1px;
				}
			}
		`,
	],
})
export class OrDivisorComponent {}
