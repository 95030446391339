import { Injectable, OnDestroy } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class BodyClassObserverService implements OnDestroy {
	private bodyClassSubjects = new Map<string, BehaviorSubject<boolean>>()
	private observer: MutationObserver

	constructor() {
		this.observer = new MutationObserver(() => {
			this.bodyClassSubjects.forEach((subject, className) => {
				const hasClass = document.body.classList.contains(className)
				subject.next(hasClass)
			})
		})

		this.observer.observe(document.body, { attributes: true, attributeFilter: ['class'] })
	}

	observeClass(className: 'principal' | 'centurion' | 'prime'): Observable<boolean> {
		if (!this.bodyClassSubjects.has(className)) {
			const initialState = document.body.classList.contains(className)
			this.bodyClassSubjects.set(className, new BehaviorSubject<boolean>(initialState))
		}
		return this.bodyClassSubjects.get(className)!.asObservable()
	}

	ngOnDestroy(): void {
		this.observer.disconnect()
		this.bodyClassSubjects.forEach(subject => subject.complete())
	}
}
