// Define this.isOnlyCelebration como true somente se todas as condições abaixo forem atendidas:
// 1. Todas as categorias estão na lista ['Celebrations', 'Guia Michelin'].
// 2. Pelo menos um dos tipos é exatamente 'Celebrations'.
// 3. props.benefits é um array vazio (Array.isArray(props.benefits) && props.benefits.length === 0).
// Usa o operador de coalescência nula (??) para garantir que o valor padrão seja false caso props seja undefined.
export function isOnlyCelebration(
	types: { type: string }[] | undefined,
	benefits: unknown[] | undefined
): boolean {
	return (
		(types?.every(category => ['Celebrations', 'Guia Michelin'].includes(category.type)) &&
			types.some(category => category.type === 'Celebrations') &&
			Array.isArray(benefits) &&
			benefits.length === 0) ??
		false
	)
}

export function hasCelebrations(types: { type: string }[] | undefined): boolean {
	return types?.some(category => category.type === 'Celebrations') ?? false
}
