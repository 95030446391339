import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { TagUiComponent } from '../tag/tag.component'
import { BenefitsUiComponent } from '../benefits/benefits.component'
import { VenueFilter } from '@monorepo-channels/channels/domain'

@Component({
	selector: 'ui-bb-venue-card',
	standalone: true,
	imports: [CommonModule, BenefitsUiComponent, TagUiComponent],
	templateUrl: './venue-card.component.html',
	styleUrls: ['./venue-card.component.scss'],
})
export class VenueCardUiComponent {
	@Input({ required: true }) venue!: VenueFilter
	@Output() clickCard = new EventEmitter<void>()
}
