<div class="benefits" *ngFor="let benefit of benefits">
	@if (benefit.type === 'points') {
		<i class="fa fa-livelo benefits__icon"></i>
		@if (campaign$ | async; as campaign) {
			@if (
				campaign.type === 'SUBSTITUTION' &&
				((campaign.venueIds && campaign.venueIds.includes(venueId)) || !campaign.venueIds)
			) {
				<p>
					Ganhe
					<span style="text-decoration: line-through; color: unset; font-weight: 400">
						{{ benefit.value }} {{ benefit.value === '1' ? 'ponto' : 'pontos' }}
					</span>
					<span class="benefits__text"> {{ campaign.value }} pontos Livelo </span>
					a cada 1 real gasto, pagando com cartão Ourocard
				</p>
			} @else if (
				campaign.type === 'MULTIPLIER' &&
				((campaign.venueIds && campaign.venueIds.includes(venueId)) || !campaign.venueIds)
			) {
				<p>
					Ganhe
					<span style="text-decoration: line-through; color: unset; font-weight: 400">
						{{ benefit.value }} {{ benefit.value === '1' ? 'ponto' : 'pontos' }}
					</span>
					<span class="benefits__text"> {{ campaign.value * +benefit.value }} pontos Livelo </span>
					a cada 1 real gasto, pagando com cartão Ourocard
				</p>
			} @else if (campaign.venueIds && !campaign.venueIds.includes(venueId)) {
				<ng-container
					*ngTemplateOutlet="defaultBenefit; context: { value: benefit.value }"
				></ng-container>
			}
		} @else {
			<ng-container
				*ngTemplateOutlet="defaultBenefit; context: { value: benefit.value }"
			></ng-container>
		}
	}
</div>

<ng-template #defaultBenefit let-value="value">
	<p>
		Ganhe<span class="benefits__text"> {{ value }} {{ value === '1' ? 'ponto' : 'pontos' }} Livelo </span>
		a cada 1 real gasto, pagando com cartão Ourocard
	</p>
</ng-template>
