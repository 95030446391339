import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { PartnerToken } from '../misc/partners.util'
import { environment } from '@monorepo-channels/shared/util-environments'
import { BehaviorSubject, tap } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class CampaignService {
	private http = inject(HttpClient)
	private partnerId = inject(PartnerToken)

	private campaignSubject = new BehaviorSubject<CampaignResponse | null>(null)
	public campaign$ = this.campaignSubject.asObservable()

	isActive(reservationDate: Date, name?: string) {
		const query = new URLSearchParams({
			partnerId: this.partnerId,
			reservationDate: reservationDate.toISOString(),
			...(name && { name }),
		}).toString()
		return this.http
			.get<CampaignResponse>(`${environment.channelsAPIURL}/api/v1/campaign/isActive?${query}`)
			.pipe(tap(campaign => this.campaignSubject.next(campaign)))
	}
}

export interface CampaignResponse {
	_id: string
	partnerId: string
	startDate: string
	endDate: string
	name: string
	description: string
	value: number
	type: 'SUBSTITUTION' | 'MULTIPLIER'
	venueIds: string[]
	banner: {
		desktop: string
		mobile: string
	}
}
