import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'

import { Router } from '@angular/router'
import { HowItWorkComponent } from './how-it-work-body/how-it-work-body.component'
import { HeaderComponent } from '../../../header/header.component'

@Component({
	standalone: true,
	selector: 'bb-how-it-work-page',
	imports: [CommonModule, HeaderComponent, HowItWorkComponent],
	template: ` <bb-header></bb-header> <bb-how-it-work />`,
})
export class HowItWorkPageComponent {
	router = inject(Router)
}
