<div class="header">
	@if(type === 'reservation') {
	<ui-common-bb-calendar-icon />
	<p class="header__text">Reserva realizada</p>
	} @else {
	<ui-common-bb-checkin-icon />
	<p class="header__text">Check-in realizado</p>
	}
</div>
<ui-common-divisor></ui-common-divisor>

<div class="info" style="margin-top: 24px">
	<p class="info__title">Mesa para</p>
	<p class="info__text">{{ partySize }} {{ partySize === 1 ? 'pessoa' : 'pessoas' }}</p>
</div>

<div class="info" style="margin-top: 24px">
	<p class="info__title">Data</p>
	<p class="info__text">{{ orderDate | date :'EEEE, d \'de\' MMMM' | capitalizeFirst }}</p>
</div>

<div class="info" style="margin-top: 24px">
	<p class="info__title">Restaurante</p>
	<p class="info__text">{{ venue.name }}</p>
</div>

<div class="info" style="margin-top: 24px" *ngIf="sectionLabel">
	<p class="info__title">Ambiente</p>
	<p class="info__text">{{ sectionLabel }}</p>
</div>

<div class="info" style="margin-top: 24px">
	<p class="info__title">Horário</p>
	<p class="info__text">{{ reservationTime }}</p>
</div>

<div class="info" style="margin-top: 24px">
	<p class="info__title">Endereço</p>
	<p class="info__text">{{venue.address.value}}</p>
</div>
<div style="height: 32px"></div>
<ui-bb-benefits [benefits]="venue.benefits" [venueId]="venue._id" />
