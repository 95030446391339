import { CommonModule } from '@angular/common'
import { Component, HostListener, OnInit, inject } from '@angular/core'

import {
	VenueCardListComponent,
	FilterComponent,
	AlertHomeFeatureComponent,
	HandleLoginComponent,
} from '@monorepo-channels/feature-bb'
import {
	ClientActions,
	LoginHomologationDirective,
	OrderHistoryActions,
	VenueActions,
} from '@monorepo-channels/channels/domain'
import { ModalBBService, SwiperUiComponent } from '@monorepo-channels/components/ui-bb'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { CampaignResponse, CampaignService, isMobile } from '@monorepo-channels/shared/util-helpers'
import { HeaderComponent } from '../../header/header.component'
import { tap } from 'rxjs'

@Component({
	standalone: true,
	imports: [
		SwiperUiComponent,
		CommonModule,
		FilterComponent,
		VenueCardListComponent,
		AlertHomeFeatureComponent,
		HandleLoginComponent,
		HeaderComponent,
	],
	templateUrl: 'venues-list.page.html',
	styleUrls: ['venues-list.page.scss'],
	hostDirectives: [LoginHomologationDirective],
})
export class VenueListPageComponent implements OnInit {
	private store = inject(Store)
	private route = inject(ActivatedRoute)
	private router = inject(Router)
	public modalBBService = inject(ModalBBService)
	campaign$ = inject(CampaignService).campaign$
	campaign: CampaignResponse | null = null

	urlsImg!: string[]
	searchName = ''
	isMobile = isMobile()

	@HostListener('window:resize')
	isMobileDevice() {
		this.isMobile = isMobile()
		this.sizeScreen()
	}

	ngOnInit(): void {
		this.store.dispatch(VenueActions.resetSelectedVenue())
		const fragment = this.route.snapshot.fragment
		this.campaign$
			.pipe(
				tap(campaign => {
					this.campaign = campaign
					this.sizeScreen()
				})
			)
			.subscribe()
		if (fragment) {
			const params = new URLSearchParams(fragment)
			const code = params.get('code')
			if (!code) return
			const goBackUrl = localStorage.getItem('go_back_url')
			this.router.navigate([goBackUrl ?? ''], { fragment: undefined })
			this.store.dispatch(ClientActions.loginClientLiveloSSO({ code }))
		} else {
			const hasAccess = localStorage.getItem('hasAccess')
			if (!hasAccess) {
				if (this.isMobile) {
					this.router.navigate(['/como-funciona'])
				} else {
					setTimeout(() => {
						this.modalBBService.openModalMenu('/como-funciona')
					}, 500)
				}
				localStorage.setItem('hasAccess', 'sim')
			}
		}

		this.sizeScreen()
	}

	sizeScreen() {
		const banner = this.campaign?.banner
		const mobileUrl = banner?.mobile?.replace(/\s/g, '%20') || null
		const desktopUrl = banner?.desktop?.replace(/\s/g, '%20') || null

		const defaultMobileUrl = 'https://tagmepub.blob.core.windows.net/pubimg/Channels/BB/bannerMobile.png'
		const defaultDesktopUrl = 'https://tagmepub.blob.core.windows.net/pubimg/Channels/BB/bannerDesk.png'

		this.urlsImg = (
			this.isMobile
				? [mobileUrl, defaultMobileUrl].filter(Boolean)
				: [desktopUrl, defaultDesktopUrl].filter(Boolean)
		) as string[]
	}

	setSearchName(name: string) {
		this.searchName = name
	}

	clickPay(orderId: string) {
		if (this.isMobile) {
			this.router.navigate([`reservas-e-pagamento/pagamento/${orderId}`])
		} else {
			this.store.dispatch(OrderHistoryActions.setSelectedOrderId({ orderId }))
			this.modalBBService.openModalOthers('payment')
		}
	}

	clickRegisterCard() {
		if (this.isMobile) {
			this.router.navigate(['/carteira/cadastrar-cartao'])
		} else {
			this.modalBBService.openModalOthers('register-card')
		}
	}

	clickBanner(url: string) {
		// TODO: better if log to handle campaign, but for now it just for working
		if (this.campaign?._id) {
			if (url === this.urlsImg[0]) {
				window.open('https://bb.com.br/temporadadeverao', '__blank')
			}

			if (url === this.urlsImg[1]) {
				if (this.isMobile) {
					this.router.navigate(['/como-funciona'])
				} else {
					this.modalBBService.openModalMenu('/como-funciona')
				}
			}
		} else {
			if (url === this.urlsImg[0]) {
				if (this.isMobile) {
					this.router.navigate(['/como-funciona'])
				} else {
					this.modalBBService.openModalMenu('/como-funciona')
				}
			}
		}
	}
}
