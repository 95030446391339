@if (vm$ | async; as vm) { @if(vm.order) { @if(paymentStage === 'value' || paymentStage === 'payment') {
<div class="page px-16">
	<h2 class="sub-title">Pagar conta</h2>
	<span class="sub-title__date">
		{{vm.order.reservationDateTime | date: 'dd/MM/YYYY'}} • {{vm.order.intent}}
	</span>
	<ui-bb-title type="primary" [styles]="{paddingTop: '0'}">{{ vm.order.venue.name }}</ui-bb-title>
	<ui-bb-benefits
		[benefits]="vm.order.benefits"
		[venueId]="vm.order.venue.id"
		style="margin-top: 8px; margin-bottom: 32px"
	/>
</div>

<div [style]="isMobile ? 'height: calc(100vh - 540px)' : ''" *ngIf="vm.statusPayment ==='pending'">
	<!-- <div style="height: calc(100vh - 540px)"> -->
	<ui-bb-loading-page>Realizando pagamento</ui-bb-loading-page>
</div>

<ng-container *ngIf="paymentStage === 'value'">
	<div class="page px-16">
		<label class="text">Digite o valor total a pagar</label>
		<form style="position: relative">
			<input
				#valuePayment
				class="input-value"
				type="text"
				placeholder="0,00"
				[formControl]="totalValue"
				inputmode="numeric"
				currencyMask
				[options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
			/>
			<ui-common-edit-svg
				style="position: absolute; right: 0; top: 10px"
				(click)="valuePayment.focus()"
			/>
		</form>

		<div class="container">
			<span class="container__text">Valor da conta</span>
			<span class="container__value">{{ value | currency: 'BRL' }}</span>
		</div>
		<ui-common-divisor />
		<div class="container">
			<div class="container__group">
				<div style="display: flex">
					<span class="container__text">Taxa de serviço</span>
					<ui-common-info-svg
						matTooltip="A Taxa de serviço padrão utilizada nesse restaurante é {{ suggestedTip }}%."
						matTooltipPosition="above"
						style="margin-left: 6px"
						*ngIf="!removeGratuityValue"
					></ui-common-info-svg>
				</div>
				<button class="container__tax" (click)="tipClick()" *ngIf="!removeGratuityValue">
					Incluir Taxa de serviço adicional
				</button>
			</div>
			<span class="container__value">{{ gratuityValue | currency: 'BRL' }}</span>
		</div>
	</div>
	<div style="background-color: var(--primitive-grey-400)" class="temp-mobile-w">
		<div class="container page px-16">
			<div style="display: flex">
				<span class="container__text points">Pontos Livelo</span>
				<ui-common-info-svg
					matTooltip="O total de pontos não inclui a Taxa de serviço."
					matTooltipPosition="above"
					style="margin-left: 6px"
				></ui-common-info-svg>
			</div>
			<span class="container__value points">{{ points }} pontos</span>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="paymentStage === 'payment' && vm.client ">
	<ng-container *ngIf="vm.statusPayment ==='idle' || vm.statusPayment === 'failure'">
		<div class="page px-16">
			<div class="card">
				<div style="display: flex; align-items: center; gap: 10px">
					<i class="fa fa-combined-shape" style="font-size: 4px"></i>
					<span calss="card__lastFourDigits">{{vm.client.selectedCategory?.lastFourDigits}}</span>
				</div>
				<p class="card__name">{{vm.client.fullName}}</p>
				<p class="card__cvv-title">Digite o CVV</p>
				<div style="display: flex">
					<input
						class="card__cvv"
						required
						maxlength="3"
						minlength="3"
						placeholder="CVV"
						[formControl]="dynamicCVV"
					/>
					<img
						style="margin-left: auto; width: 65px"
						src="/assets/images/{{ vm.client.selectedCategory?.imageBrand?.src }}"
						[alt]="vm.client.selectedCategory?.imageBrand?.alt"
					/>
				</div>
			</div>
			<ui-common-edit-svg
				type="line"
				class="card__change-card__icon"
				(click)="changeCardClick()"
				*ngIf="isMobile"
			/>
			<button class="card__change-card" (click)="changeCardClick()" *ngIf="isMobile">
				Trocar cartão
			</button>
		</div>
	</ng-container>
</ng-container>

<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'">
	<div class="page px-16" style="display: flex">
		<div style="margin-right: auto">
			<p style="color: var(--primitives-grey-500); font-size: 14px; line-height: 16px">
				Valor total a pagar
			</p>
			<p style="color: var(--content-primary); font-weight: 500; font-size: 24px">
				{{totalValue.value | currency: 'BRL'}}
			</p>
		</div>
		<ui-bb-button
			type="primary"
			style="width: 126px"
			(clickButton)="nextStage()"
			[disabled]="disabledForStage() || vm.statusPayment === 'pending'"
		>
			<span>PAGAR CONTA</span>
		</ui-bb-button>
	</div>
</ui-bb-footer>

}

<ng-container *ngIf="paymentStage === 'payment-success'">
	<div class="hero">
		<ui-common-livelo-points />
		<p class="hero__title">Parabéns você acumulou</p>
		<p class="hero__points">{{vm.paymentOrder?.points}} pontos Livelo</p>
	</div>
	<ui-common-divisor />
	<div class="page px-16">
		<div class="info" style="margin-top: 24px">
			<p class="info__title">Valor pago</p>
			<p class="info__text" *ngIf="vm.paymentOrder?.totalValue">
				{{ vm.paymentOrder!.totalValue / 100 | currency: 'BRL'}}
			</p>
		</div>
		<div class="info" style="margin-top: 24px">
			<p class="info__title">Cartão de crédito</p>
			<p class="info__text" style="display: flex; align-items: center; gap: 8px">
				<i class="fa fa-combined-shape" style="font-size: 4px"></i>
				{{ vm.client?.selectedCategory?.lastFourDigits}}
			</p>
		</div>
		<div class="info" style="margin-top: 24px">
			<p class="info__title">Data</p>
			<p class="info__text">{{vm.paymentOrder?.paidAt | date : 'd \'de\' MMMM, y'}}</p>
		</div>
		<div class="info" style="margin-top: 24px">
			<p class="info__title">Restaurante</p>
			<p class="info__text">{{vm.order.venue.name}}</p>
		</div>
		<div class="info" style="margin-top: 24px">
			<p class="info__title">Endereço</p>
			<p class="info__text">{{vm.order.venue.address.value}}</p>
		</div>
	</div>
	<div class="offer-bb-livelo">
		<p class="offer-bb-livelo__title">Um Oferecimento</p>
		<img src="/assets/images/bb-benefits.png" style="width: 273px" />
	</div>
	<ui-bb-footer [position]="isMobile ? 'fixed' : 'static'">
		<div class="page px-16">
			<ui-bb-button type="secondary-ghost" style="width: 100%" (clickButton)="goToHome()">
				ENTENDIDO
			</ui-bb-button>
		</div>
	</ui-bb-footer>
</ng-container>

<ng-container *ngIf="isMobile">
	<ui-bb-modal-bottom title="Alterar Taxa de serviço do garçom?" height="290px" #modalTip>
		<div style="display: flex; margin-top: 32px">
			<p class="modal-tip__suggested-value">Valor sugerido: {{suggestedTip}}%</p>
			<p class="modal-tip__tip">{{tip.value}}%</p>
		</div>
		<div style="margin-top: 16px">
			<input
				type="range"
				class="modal-tip__range"
				[min]="suggestedTip"
				max="25"
				[defaultValue]="suggestedTip"
				[formControl]="tip"
			/>
		</div>
		<ui-bb-button
			type="secondary"
			style="width: 100%; padding: 16px"
			footer
			(clickButton)="applyTip(modalTip)"
		>
			CONFIRMAR
		</ui-bb-button>
	</ui-bb-modal-bottom>

	<ui-bb-modal-bottom title="Selecionar cartão" height="380px" overflow="auto" #modalChangeCard>
		<div style="display: flex; margin-top: 24px"></div>
		<ul class="card-list">
			<li *ngFor="let card of vm.client?.cards">
				<div
					class="card-list__moving"
					#cardElement
					(click)="selectCard(card.gatewayId, card.lastFourDigits); modalChangeCard.closeModal()"
				>
					<ui-common-bb-background-card />
					<i class="fa fa-combined-shape"></i>
					<p class="lastFourDigits">{{ card.lastFourDigits }}</p>
					<img
						class="brand"
						src="/assets/images/{{ card.imageBrand?.src }}"
						[alt]="card.imageBrand?.alt"
					/>
					<p class="default" *ngIf="card.mainCard">Padrão</p>
				</div>
			</li>
		</ul>
		<div style="height: 16px"></div>
		<p class="new-card" routerLink="/carteira/cadastrar-cartao">+ ADICIONAR CARTÃO</p>
		<ui-bb-button
			footer
			type="secondary"
			style="width: 100%; padding: 16px"
			(clickButton)="applyTip(modalTip)"
		>
			CONTINUAR
		</ui-bb-button>
	</ui-bb-modal-bottom>

	<ui-bb-modal-bottom-failure title="Pagamento não realizado" #modalFailurePayment>
		O meio de pagamento, cartão {{vm.client?.selectedCategory?.lastFourDigits}}, foi recusado. <br />
		Para efetuar essa compra, indique outro cartão.
	</ui-bb-modal-bottom-failure>
</ng-container>

<ng-container *ngIf="!isMobile">
	<ui-bb-modal-center title="Alterar Taxa de serviço do garçom?" height="290px" #modalCenterTip>
		<div style="padding: 12px">
			<div style="display: flex; margin-top: 32px">
				<p class="modal-tip__suggested-value">Valor sugerido: {{suggestedTip}}%</p>
				<p class="modal-tip__tip">{{tip.value}}%</p>
			</div>
			<div style="margin-top: 16px">
				<input
					type="range"
					class="modal-tip__range"
					[min]="suggestedTip"
					max="25"
					[defaultValue]="suggestedTip"
					[formControl]="tip"
				/>
			</div>
			<ui-bb-button
				type="secondary"
				style="width: 100%; padding: 16px"
				footer
				(clickButton)="applyTip(modalTip)"
			>
				CONFIRMAR
			</ui-bb-button>
		</div>
	</ui-bb-modal-center>

	<!-- <ui-bb-modal-center title="Selecionar cartão" height="380px" overflow="auto" #modalChangeCardModalCenter>
		<div style="display: flex; margin-top: 24px"></div>
		<ul class="card-list">
			<li *ngFor="let card of vm.client?.cards">
				<div
					class="card-list__moving"
					#cardElement
					(click)="selectCard(card.gatewayId, card.lastFourDigits); modalChangeCardModalCenter.closeModal()"
				>
					<ui-common-bb-background-card />
					<i class="fa fa-combined-shape"></i>
					<p class="lastFourDigits">{{ card.lastFourDigits }}</p>
					<img
						class="brand"
						src="/assets/images/{{ card.imageBrand?.src }}"
						[alt]="card.imageBrand?.alt"
					/>
					<p class="default" *ngIf="card.mainCard">Padrão</p>
				</div>
			</li>
		</ul>
		<div style="height: 16px"></div>
		<p class="new-card" routerLink="/carteira/cadastrar-cartao">+ ADICIONAR CARTÃO</p>
		<ui-bb-button
			footer
			type="secondary"
			style="width: 100%; padding: 16px"
			(clickButton)="applyTip(modalTip)"
		>
			CONTINUAR
		</ui-bb-button>
	</ui-bb-modal-center> -->

	<ui-bb-modal-center title="Pagamento não realizado" #modalFailurePaymentDesktop>
		<div style="padding: 12px">
			O meio de pagamento, cartão {{vm.client?.selectedCategory?.lastFourDigits}}, foi recusado. <br />
			Para efetuar essa compra, indique outro cartão.
		</div>
	</ui-bb-modal-center>
</ng-container>
} }
