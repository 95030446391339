export class CoverURL {
	original: string
	value: string
	constructor(url: string | undefined, optimized: string | undefined) {
		const chooseURL = optimized || url || ''
		// const BASE_URL = 'https://tagmepub.azureedge.net'
		const BASE_URL =
			'https://tagmeimageservice.azurewebsites.net/?q=90&url=https://tagmepub.azureedge.net'
		const PUB_IMG = BASE_URL + '/pubimg'
		this.value = `${PUB_IMG}/${chooseURL}`
		this.original = `${PUB_IMG}/${url}`
	}
}
